<template>
	<div class="info-cell flex" :style="[{backgroundImage: `url(${cellBackgroundUrl})`}, customerStyle]">
		<div class="info-cell-l flex">
			<template v-if="$slots.left">
				<slot name="left"></slot>
			</template>
			<AvatarImg
					:avatar="avatar"
					:avatarHeight="avatarHeight"
					:round="round"
					:border="border"
					:borderColor="borderColor"
					:liveLout="liveLout"
			/>
			<div class=" ml-1 the-flex flex-1"
			     :style="{'flex-direction' : contextFollowDirection,
			      'justify-content': contextJustifyContent,
			      'align-items': myAlignItems,
			      'align-self' : contextAlignSelf
			      }">
				<slot name="context"></slot>
			</div>
		</div>
		<div class="info-cell-r flex-right">
			<slot name="right"></slot>
		</div>
	</div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import AvatarImg from './AvatarImg'

const props = defineProps({
	avatar: {
		type: String,
		default: ''
	},
	// 图片高度
	avatarHeight: {
		type: [Number, String],
		default: '.4rem'
	},
	// 是否为圆形
	round: {
		type: Boolean,
		default: true
	},
	border: {
		type: Boolean,
		default: false
	},
	borderColor: {
		type: String,
		default: '#1977FF'
	},
	liveLout: {
		type: Boolean,
		default: false
	},
	// 单元格背景色
	cellBackgroundColor: {
		type: String,
		default: '#ffffff'
	},
	// 单元格背景图片
	cellBackgroundUrl: {
		type: String,
		default: ''
	},
	// 设置样式
	customerStyle: {
		type: Object,
		default: () => {
			return {}
		}
	},
	// 内容区域方向
	contextFollowDirection: {
		type: String,
		default: 'row'
	},
	contextJustifyContent: {
		type: String,
		default: ''
	},
	contextAlignItems: {
		type: String,
		default: ''
	},
	contextAlignSelf: {
		type: String,
		default: ''
	}
})
const myAlignItems = computed(() => {
	return props.contextAlignItems ?
			props.contextAlignItems :
			(props.contextFollowDirection !== 'row' ? '' : 'center')
})
</script>

<style scoped lang="scss">
.info-cell {
	padding: 0.15rem 0.1rem;
	background-color: v-bind(cellBackgroundColor);
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	box-sizing: border-box;
	&:after {
		position: absolute;
		box-sizing: border-box;
		content: " ";
		pointer-events: none;
		right: px(15);
		bottom: 0;
		left: px(15);
		border-bottom: 1px solid #F1F1F1;
		transform: scaleY(.5);
	}

	&:last-child:after {
		display: none;
	}

	.info-cell-l {
		flex: 1;
	}
}

.the-flex {
	box-sizing: border-box;
	display: flex;
}

</style>
