<template>
	<div
			class="item--avatar  relative"
			:class="{'img-round': round}"
			:style=" {
					width: avatarHeight,
					height: avatarHeight
			}"
	>
		<img v-lazy="myAvatar"
		     class="avatar-img"
		     :class="{'avatar-border' : border, 'img-round': round}"
		     :style=" {
					width: avatarHeight,
					height: avatarHeight
			}"
		     alt=""/>
		<div
				:style=" {
					width: avatarHeight,
					height: avatarHeight
			}"
				class="live_out flex h-center" :class="{ 'img-round': round}" v-if="liveLout">离线
		</div>
	</div>
</template>

<script>


export default {
	name: 'AvatarImg'
}
</script>
<script setup>
import avatarImg from './assets/avatar.png'
import avatarSquareImg from './assets/avatar-square.png'
import { computed } from 'vue'

const props = defineProps({
	avatarHeight: {
		type: String,
		default: '.4rem'
	},
	avatar: {
		type: String,
		default: ''
	},
	border: {
		type: Boolean,
		default: false
	},
	borderColor: {
		type: String,
		default: '#07C160'
	},
	liveLout: {
		type: Boolean,
		default: false
	},
	// 是否为圆形
	round: {
		type: Boolean,
		default: true
	}
})

const myAvatar = computed(() => {
	return props.avatar
			? props.avatar
			: (props.round ? avatarImg : avatarSquareImg)
})
</script>
<style scoped>

.avatar-img, .item--avatar {
	width: v-bind('props.avatarHeight');
	height: v-bind('props.avatarHeight');
	border-radius: 6px;
}

.live_out {
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	width: v-bind('props.avatarHeight');
	height: v-bind('props.avatarHeight');
	font-size: 12px;
	color: #fff;
	border-radius: 6px;
	background: rgba(22, 24, 29, 0.5);
}

.img-round{
	border-radius: 50%;
}

.avatar-border {
	box-sizing:border-box;
	border: 1px solid v-bind('props.borderColor');
}
</style>
